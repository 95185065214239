import React, { useState, useEffect } from "react"
import { format, addDays, startOfWeek, subWeeks, addWeeks, isBefore, startOfDay } from 'date-fns';
import { Calendar, Clock, Video, MapPin, X, Edit, Check, ChevronLeft, ChevronRight, AlertCircle } from "lucide-react"
import {
  Box,
  Typography,
  Button,
  IconButton,
  Chip,
  Grid,
  Divider,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Tabs,
  Tab,
  Drawer,
} from "@mui/material"

// Enhanced appointments data with more details
const appointmentsData = [
  {
    id: 1,
    extension: [
      { url: "recorded_on", valueString: "Apr 10, 2025" },
      { url: "time", valueString: "5:30 PM" },
      { url: "provider", valueString: "Harp Scott" },
      { url: "provider_role", valueString: "Psychiatrist" },
      { url: "provider_image", valueString: "/placeholder.svg?height=100&width=100" },
      { url: "type_of_visit", valueString: "Virtual visit" },
      { url: "status", valueString: "Pending" },
      { url: "status_color", valueString: "#FB8900" },
      { url: "facility", valueString: "McLean hospital" },
      { url: "virtual_link", valueString: "https://meet.google.com/hnt-hqkp-wyr" },
      { url: "payment_status", valueString: "Paid" },
      { url: "payment_amount", valueString: "$150.00" },
      { url: "payment_method", valueString: "Insurance (Aetna)" },
      {
        url: "notes",
        valueString: "Follow-up appointment for heart condition. Please bring your latest test results.",
      },
      { headerName: "", type: "button", dataCol: "View details" },
    ],
  },
  {
    id: 2,
    extension: [
      { url: "recorded_on", valueString: "Feb 25, 2025" },
      { url: "time", valueString: "2:00 PM" },
      { url: "provider", valueString: "William Carter" },
      { url: "provider_role", valueString: "Cardiologist" },
      { url: "provider_image", valueString: "/placeholder.svg?height=100&width=100" },
      { url: "type_of_visit", valueString: "Virtual visit" },
      { url: "status", valueString: "Not scheduled" },
      { url: "status_color", valueString: "#9E9E9E" },
      { url: "facility", valueString: "GoHealth urgent care" },
      { url: "virtual_link", valueString: "https://meet.google.com/abc-defg-hij" },
      { url: "payment_status", valueString: "Pending" },
      { url: "payment_amount", valueString: "$75.00" },
      { url: "payment_method", valueString: "Credit card" },
      { url: "notes", valueString: "Annual check-up appointment." },
      { headerName: "", type: "button", dataCol: "View details" },
    ],
  },
  {
    id: 3,
    extension: [
      { url: "recorded_on", valueString: "Mar 10, 2025" },
      { url: "time", valueString: "10:15 AM" },
      { url: "provider", valueString: "Sarah Johnson" },
      { url: "provider_role", valueString: "Dermatologist" },
      { url: "provider_image", valueString: "/placeholder.svg?height=100&width=100" },
      { url: "type_of_visit", valueString: "In-person" },
      { url: "status", valueString: "Confirmed" },
      { url: "status_color", valueString: "#4CAF50" },
      { url: "facility", valueString: "City medical center" },
      { url: "payment_status", valueString: "Paid" },
      { url: "payment_amount", valueString: "$200.00" },
      { url: "payment_method", valueString: "Insurance (Blue Cross)" },
      { url: "notes", valueString: "Skin examination and mole check." },
      { headerName: "", type: "button", dataCol: "View details" },
    ],
  },
  {
    id: 4,
    extension: [
      { url: "provider", valueString: "Michael Brown" },
      { url: "provider_role", valueString: "Orthopedic surgeon" },
      { url: "provider_image", valueString: "/placeholder.svg?height=100&width=100" },
      { url: "facility", valueString: "Memorial hospital" },
      { url: "status", valueString: "Not scheduled" },
      { url: "status_color", valueString: "#9E9E9E" },
      { headerName: "", type: "button", dataCol: "Schedule" },
    ],
  },
]

// Function to get value from extension array
const getValue = (extension, url) => {
  const item = extension.find((item) => item.url === url)
  return item ? item.valueString : ""
}

// Available time slots for scheduling
const timeSlots = {
  morning: ["9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM"],
  afternoon: ["1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM"],
  evening: ["4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM"],
}

// Status badge component
const StatusBadge = ({ status, color }) => {
  const getColor = () => {
    if (status === "Confirmed") return "success"
    if (status === "Pending") return "warning"
    if (status === "Cancelled") return "error"
    return "default"
  }

  return (
    <Chip
      label={status}
      color={getColor()}
      size="small"
      sx={{
        fontWeight: 500,
        borderRadius: "16px",
      }}
    />
  )
}

// Date picker component
const DatePicker = ({ selectedDate, onDateChange }) => {
  const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(new Date(), { weekStartsOn: 0 }));

  const days = Array.from({ length: 14 }, (_, i) => {
    const date = addDays(currentWeekStart, i);
    const dayName = format(date, "EEE");
    const dayNumber = format(date, "d");
    const isToday = format(date, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd");
    const isSelected = format(date, "yyyy-MM-dd") === format(selectedDate, "yyyy-MM-dd");
    const isPastDate = isBefore(date, startOfDay(new Date())); // Check if date is in the past

    return { date, dayName, dayNumber, isToday, isSelected, isPastDate };
  });

  const handlePrevWeek = () => {
    const newWeekStart = subWeeks(currentWeekStart, 1);
    // Prevent going to previous weeks if all dates would be in the past
    if (!isBefore(addDays(newWeekStart, 13), startOfDay(new Date()))) {
      setCurrentWeekStart(newWeekStart);
    }
  };

  const handleNextWeek = () => {
    setCurrentWeekStart(addWeeks(currentWeekStart, 1));
  };

  const handleDateClick = (day) => {
    // Only allow date selection if it's not a past date
    if (!day.isPastDate) {
      onDateChange(day.date);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
        <IconButton size="small" onClick={handlePrevWeek}>
          <ChevronLeft size={18} />
        </IconButton>
        <Typography fontWeight={500}>
          {format(currentWeekStart, "MMMM yyyy")}
        </Typography>
        <IconButton size="small" onClick={handleNextWeek}>
          <ChevronRight size={18} />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          pb: 1,
          gap: 1,
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
        }}
      >
        {days.map((day) => (
          <Paper
            key={day.date.toString()}
            elevation={0}
            onClick={() => handleDateClick(day)}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "60px",
              height: "70px",
              borderRadius: 2,
              border: "1px solid",
              borderColor: day.isSelected ? "primary.main" 
                       : day.isPastDate ? "divider" 
                       : "divider",
              backgroundColor: day.isSelected ? "primary.main" 
                            : day.isToday ? "action.hover" 
                            : day.isPastDate ? "action.disabledBackground" 
                            : "background.paper",
              color: day.isSelected ? "primary.contrastText" 
                   : day.isPastDate ? "text.disabled" 
                   : "text.primary",
              cursor: day.isPastDate ? "not-allowed" : "pointer",
              transition: "all 0.2s",
              "&:hover": {
                backgroundColor: day.isPastDate ? "action.disabledBackground" 
                              : day.isSelected ? "primary.main" 
                              : "action.hover",
              },
              opacity: day.isPastDate ? 0.7 : 1,
            }}
          >
            <Typography variant="caption" fontWeight={500}>
              {day.dayName}
            </Typography>
            <Typography variant="h6" fontWeight={600}>
              {day.dayNumber}
            </Typography>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export const Appointments = ({ drawerOpen, setDrawerOpen, appointmentId }) => {
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [rescheduleDialogOpen, setRescheduleDialogOpen] = useState(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedTime, setSelectedTime] = useState("")
  const [selectedVisitType, setSelectedVisitType] = useState("Virtual Visit")
  const [timeOfDay, setTimeOfDay] = useState("morning")
  const [tabValue, setTabValue] = useState("morning")

  useEffect(() => {
    if (appointmentId) {
      const appointment = appointmentsData.find((app) => app.id === Number.parseInt(appointmentId))
      setSelectedAppointment(appointment)
    }
  }, [appointmentId, drawerOpen])

  const handleCloseDrawer = () => {
    setDrawerOpen(false)
    setSelectedAppointment(null)
  }

  const handleReschedule = () => {
    setRescheduleDialogOpen(true)
  }

  const handleCancelAppointment = () => {
    setCancelDialogOpen(true)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    setTimeOfDay(newValue)
    setSelectedTime("") // Reset selected time when changing time of day
  }

  const confirmReschedule = () => {
    // Update the appointment with new date and time
    if (selectedAppointment && selectedDate && selectedTime) {
      const updatedExtension = [...selectedAppointment.extension]
      const formattedDate = format(selectedDate, "MMM d, yyyy")

      // Find and update date
      const dateIndex = updatedExtension.findIndex((item) => item.url === "recorded_on")
      if (dateIndex >= 0) {
        updatedExtension[dateIndex] = { ...updatedExtension[dateIndex], valueString: formattedDate }
      } else {
        updatedExtension.push({ url: "recorded_on", valueString: formattedDate })
      }

      // Find and update time
      const timeIndex = updatedExtension.findIndex((item) => item.url === "time")
      if (timeIndex >= 0) {
        updatedExtension[timeIndex] = { ...updatedExtension[timeIndex], valueString: selectedTime }
      } else {
        updatedExtension.push({ url: "time", valueString: selectedTime })
      }

      // Find and update visit type
      const typeIndex = updatedExtension.findIndex((item) => item.url === "type_of_visit")
      if (typeIndex >= 0) {
        updatedExtension[typeIndex] = { ...updatedExtension[typeIndex], valueString: selectedVisitType }
      } else {
        updatedExtension.push({ url: "type_of_visit", valueString: selectedVisitType })
      }

      // Update status to Pending
      const statusIndex = updatedExtension.findIndex((item) => item.url === "status")
      if (statusIndex >= 0) {
        updatedExtension[statusIndex] = { ...updatedExtension[statusIndex], valueString: "Pending" }
      }

      // Update status color
      const colorIndex = updatedExtension.findIndex((item) => item.url === "status_color")
      if (colorIndex >= 0) {
        updatedExtension[colorIndex] = { ...updatedExtension[colorIndex], valueString: "#FB8900" }
      }

      // Update the selected appointment
      setSelectedAppointment({
        ...selectedAppointment,
        extension: updatedExtension,
      })
    }

    setRescheduleDialogOpen(false)
  }

  const confirmCancel = () => {
    // Update the appointment status to Cancelled
    if (selectedAppointment) {
      const updatedExtension = [...selectedAppointment.extension]

      // Find and update status
      const statusIndex = updatedExtension.findIndex((item) => item.url === "status")
      if (statusIndex >= 0) {
        updatedExtension[statusIndex] = { ...updatedExtension[statusIndex], valueString: "Cancelled" }
      }

      // Find and update status color
      const colorIndex = updatedExtension.findIndex((item) => item.url === "status_color")
      if (colorIndex >= 0) {
        updatedExtension[colorIndex] = { ...updatedExtension[colorIndex], valueString: "#F44336" }
      }

      // Update the selected appointment
      setSelectedAppointment({
        ...selectedAppointment,
        extension: updatedExtension,
      })
    }

    setCancelDialogOpen(false)
  }

  const confirmSchedule = () => {
    // Schedule a new appointment
    if (selectedAppointment && selectedDate && selectedTime) {
      const updatedExtension = [...selectedAppointment.extension]
      const formattedDate = format(selectedDate, "MMM d, yyyy")

      // Add date
      const dateIndex = updatedExtension.findIndex((item) => item.url === "recorded_on")
      if (dateIndex >= 0) {
        updatedExtension[dateIndex] = { ...updatedExtension[dateIndex], valueString: formattedDate }
      } else {
        updatedExtension.push({ url: "recorded_on", valueString: formattedDate })
      }

      // Add time
      const timeIndex = updatedExtension.findIndex((item) => item.url === "time")
      if (timeIndex >= 0) {
        updatedExtension[timeIndex] = { ...updatedExtension[timeIndex], valueString: selectedTime }
      } else {
        updatedExtension.push({ url: "time", valueString: selectedTime })
      }

      // Update visit type
      const typeIndex = updatedExtension.findIndex((item) => item.url === "type_of_visit")
      if (typeIndex >= 0) {
        updatedExtension[typeIndex] = { ...updatedExtension[typeIndex], valueString: selectedVisitType }
      } else {
        updatedExtension.push({ url: "type_of_visit", valueString: selectedVisitType })
      }

      // Update status
      const statusIndex = updatedExtension.findIndex((item) => item.url === "status")
      if (statusIndex >= 0) {
        updatedExtension[statusIndex] = { ...updatedExtension[statusIndex], valueString: "Pending" }
      } else {
        updatedExtension.push({ url: "status", valueString: "Pending" })
      }

      // Update status color
      const colorIndex = updatedExtension.findIndex((item) => item.url === "status_color")
      if (colorIndex >= 0) {
        updatedExtension[colorIndex] = { ...updatedExtension[colorIndex], valueString: "#FB8900" }
      } else {
        updatedExtension.push({ url: "status_color", valueString: "#FB8900" })
      }

      // Add virtual link if virtual visit
      if (selectedVisitType === "Virtual Visit") {
        const linkIndex = updatedExtension.findIndex((item) => item.url === "virtual_link")
        if (linkIndex >= 0) {
          updatedExtension[linkIndex] = {
            ...updatedExtension[linkIndex],
            valueString: "https://meet.google.com/new-meeting-link",
          }
        } else {
          updatedExtension.push({
            url: "virtual_link",
            valueString: "https://meet.google.com/new-meeting-link",
          })
        }
      }

      // Update button text
      const buttonIndex = updatedExtension.findIndex((item) => item.type === "button")
      if (buttonIndex >= 0) {
        updatedExtension[buttonIndex] = { ...updatedExtension[buttonIndex], dataCol: "View details" }
      }

      // Update the selected appointment
      setSelectedAppointment({
        ...selectedAppointment,
        extension: updatedExtension,
      })
    }

    setScheduleDialogOpen(false)
  }

  const isAppointmentScheduled = (appointment) => {
    return getValue(appointment.extension, "status") !== "Not scheduled"
  }

  if (!selectedAppointment) return null

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: "450px", md: "500px" },
            maxWidth: "100%",
            borderRadius: { xs: 0, sm: "16px 0 0 16px" },
            boxShadow: 3,
            mt: { xs: 0, sm: 2 },
            mb: { xs: 0, sm: 2 },
            mr: { xs: 0, sm: 2 },
            height: { xs: "100%", sm: "calc(100% - 32px)" },
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <IconButton edge="start" onClick={handleCloseDrawer} sx={{ mr: 1 }}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h6" fontWeight={600}>
              Appointment details
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, overflow: "auto" }}>
            <Box sx={{ p: 2, bgcolor: "action.hover" }}>
              <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                <Avatar
                  src={getValue(selectedAppointment.extension, "provider_image")}
                  sx={{ width: 56, height: 56, border: "2px solid white", boxShadow: 1 }}
                />
                <Box>
                  <Typography variant="h6" fontWeight={600}>
                    {getValue(selectedAppointment.extension, "provider")}
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    {getValue(selectedAppointment.extension, "provider_role")}
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    {getValue(selectedAppointment.extension, "facility")}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {isAppointmentScheduled(selectedAppointment) ? (
              <>
                <Box sx={{ p: 2, borderBottom: "1px solid", borderColor: "divider" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1.5 }}>
                    <Typography variant="subtitle1" fontWeight={600}>
                      Appointment status
                    </Typography>
                    <StatusBadge
                      status={getValue(selectedAppointment.extension, "status")}
                      color={getValue(selectedAppointment.extension, "status_color")}
                    />
                  </Box>

                  <Paper elevation={0} sx={{ p: 2, bgcolor: "action.hover", borderRadius: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
                          <Calendar size={20} color="#1976d2" />
                          <Box>
                            <Typography variant="caption" fontWeight={600} color="text.secondary">
                              Date
                            </Typography>
                            <Typography variant="body1" fontWeight={600} color="text.primary">
                              {getValue(selectedAppointment.extension, "recorded_on")}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
                          <Clock size={20} color="#1976d2" />
                          <Box>
                            <Typography variant="caption" fontWeight={600} color="text.secondary">
                              Time
                            </Typography>
                            <Typography variant="body1" fontWeight={600} color="text.primary">
                              {getValue(selectedAppointment.extension, "time")}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
                          {getValue(selectedAppointment.extension, "type_of_visit") === "Virtual Visit" ? (
                            <Video size={20} color="#1976d2" />
                          ) : (
                            <MapPin size={20} color="#1976d2" />
                          )}
                          <Box>
                            <Typography variant="caption" fontWeight={600} color="text.secondary">
                              Visit type
                            </Typography>
                            <Typography variant="body1" fontWeight={600} color="text.primary">
                              {getValue(selectedAppointment.extension, "type_of_visit")}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>

                  {getValue(selectedAppointment.extension, "type_of_visit") === "Virtual Visit" &&
                    getValue(selectedAppointment.extension, "virtual_link") && (
                      <Button
                        variant="contained"
                        fullWidth
                        startIcon={<Video size={18} />}
                        href={getValue(selectedAppointment.extension, "virtual_link")}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          mt: 2,
                          bgcolor: "#2e7d32",
                          "&:hover": { bgcolor: "#1b5e20" },
                          textTransform: "none",
                          fontWeight: 600,
                        }}
                      >
                        Join virtual visit
                      </Button>
                    )}
                </Box>

                <Box sx={{ p: 2, borderBottom: "1px solid", borderColor: "divider" }}>
                  <Typography variant="subtitle1" fontWeight={600} sx={{ mb: 1.5 }}>
                    Payment information
                  </Typography>

                  <Paper elevation={0} sx={{ p: 2, bgcolor: "action.hover", borderRadius: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="caption" fontWeight={600} color="text.secondary">
                          Status
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 0.5,
                            color:
                              getValue(selectedAppointment.extension, "payment_status") === "Paid"
                                ? "#2e7d32"
                                : "#1976d2",
                          }}
                        >
                          {getValue(selectedAppointment.extension, "payment_status") === "Paid" && (
                            <Check size={16} style={{ marginRight: 4 }} />
                          )}
                          <Typography
                            variant="body1"
                            fontWeight={600}
                            color={
                              getValue(selectedAppointment.extension, "payment_status") === "Paid"
                                ? "#2e7d32"
                                : "#1976d2"
                            }
                          >
                            {getValue(selectedAppointment.extension, "payment_status") || "N/A"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="caption" fontWeight={600} color="text.secondary">
                          Amount
                        </Typography>
                        <Typography variant="body1" fontWeight={600} color="text.primary" sx={{ mt: 0.5 }}>
                          {getValue(selectedAppointment.extension, "payment_amount") || "N/A"}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="caption" fontWeight={600} color="text.secondary">
                          Method
                        </Typography>
                        <Typography variant="body1" fontWeight={600} color="text.primary" sx={{ mt: 0.5 }}>
                          {getValue(selectedAppointment.extension, "payment_method") || "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>

                {getValue(selectedAppointment.extension, "notes") && (
                  <Box style={{ padding: 16 }}>
                    <Typography variant="subtitle1" fontWeight={600} sx={{ mb: 1.5 }}>
                      Notes
                    </Typography>

                    <Paper elevation={0} sx={{ p: 2, bgcolor: "action.hover", borderRadius: 2 }}>
                      <Typography variant="body1" color="text.primary">
                        {getValue(selectedAppointment.extension, "notes")}
                      </Typography>
                    </Paper>
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ p: 4, display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 1.5,
                    borderRadius: "50%",
                    bgcolor: "action.hover",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <Calendar size={24} color="#1976d2" />
                </Paper>
                <Typography variant="h6" fontWeight={500} sx={{ mb: 1 }}>
                  Schedule an appointment with {getValue(selectedAppointment.extension, "provider")}
                </Typography>
                <Typography variant="body1" color="text.primary" sx={{ mb: 3 }}>
                  No appointment scheduled yet. Choose a convenient time for your visit.
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setScheduleDialogOpen(true)}
                  sx={{ textTransform: "none", fontWeight: 600 }}
                >
                  Schedule now
                </Button>
              </Box>
            )}
          </Box>

          {isAppointmentScheduled(selectedAppointment) &&
            getValue(selectedAppointment.extension, "status") !== "Cancelled" && (
              <Box
                sx={{
                  p: 2,
                  borderTop: "1px solid",
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<Edit size={18} />}
                  onClick={handleReschedule}
                  sx={{ textTransform: "none" }}
                >
                  Reschedule
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<X size={18} />}
                  onClick={handleCancelAppointment}
                  sx={{ textTransform: "none" }}
                >
                  Cancel appointment
                </Button>
              </Box>
            )}
        </Box>
      </Drawer>

      {/* Reschedule Dialog */}
      <Dialog
        open={rescheduleDialogOpen}
        onClose={() => setRescheduleDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{ sx: { borderRadius: 2 } }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" fontWeight={600}>
            Reschedule appointment
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Select a new date and time for your appointment with {getValue(selectedAppointment?.extension, "provider")}.
          </DialogContentText>

          <Box sx={{ mt: 3, mb: 3 }}>
            <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 1 }}>
              Select date
            </Typography>
            <DatePicker selectedDate={selectedDate} onDateChange={setSelectedDate} />
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 1 }}>
              Select time
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab label="Morning" value="morning" />
                <Tab label="Afternoon" value="afternoon" />
                <Tab label="Evening" value="evening" />
              </Tabs>
            </Box>

            <Grid container spacing={1.5}>
              {timeSlots[timeOfDay].map((time) => (
                <Grid item xs={4} key={time}>
                  <Button
                    variant={selectedTime === time ? "contained" : "outlined"}
                    fullWidth
                    onClick={() => setSelectedTime(time)}
                    sx={{
                      textTransform: "none",
                      borderRadius: 1.5,
                      py: 0.75,
                    }}
                  >
                    {time}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box>
            <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 1 }}>
              Visit type
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant={selectedVisitType === "Virtual Visit" ? "contained" : "outlined"}
                  fullWidth
                  startIcon={<Video size={18} />}
                  onClick={() => setSelectedVisitType("Virtual Visit")}
                  sx={{ textTransform: "none", justifyContent: "flex-start" }}
                >
                  Virtual visit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant={selectedVisitType === "In-Person" ? "contained" : "outlined"}
                  fullWidth
                  startIcon={<MapPin size={18} />}
                  onClick={() => setSelectedVisitType("In-Person")}
                  sx={{ textTransform: "none", justifyContent: "flex-start" }}
                >
                  In-person
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 1 }}>
          <Button onClick={() => setRescheduleDialogOpen(false)} sx={{ textTransform: "none" }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={confirmReschedule}
            disabled={!selectedDate || !selectedTime}
            sx={{ textTransform: "none", fontWeight: 600 }}
          >
            Confirm reschedule
          </Button>
        </DialogActions>
      </Dialog>

      {/* Cancel Dialog */}
      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{ sx: { borderRadius: 2 } }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" fontWeight={600}>
            Cancel appointment
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel your appointment with {getValue(selectedAppointment?.extension, "provider")}{" "}
            on {getValue(selectedAppointment?.extension, "recorded_on")} at{" "}
            {getValue(selectedAppointment?.extension, "time")}?
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "error.main",
              mt: 2,
            }}
          >
            <AlertCircle size={18} style={{ marginRight: 8 }} />
            <Typography variant="body2" fontWeight={500}>
              This action cannot be undone.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 1 }}>
          <Button onClick={() => setCancelDialogOpen(false)} sx={{ textTransform: "none" }}>
            No, keep it
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={confirmCancel}
            sx={{ textTransform: "none", fontWeight: 600 }}
          >
            Yes, cancel appointment
          </Button>
        </DialogActions>
      </Dialog>

      {/* Schedule Dialog */}
      <Dialog
        open={scheduleDialogOpen}
        onClose={() => setScheduleDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{ sx: { borderRadius: 2 } }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" fontWeight={600}>
            Schedule appointment
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Schedule an appointment with {getValue(selectedAppointment?.extension, "provider")}.
          </DialogContentText>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
            <Avatar src={getValue(selectedAppointment?.extension, "provider_image")} sx={{ width: 40, height: 40 }} />
            <Box>
              <Typography variant="body1" fontWeight={500}>
                {getValue(selectedAppointment?.extension, "provider")}
              </Typography>
              <Typography variant="body1" color="text.primary">
                {getValue(selectedAppointment?.extension, "provider_role")} •{" "}
                {getValue(selectedAppointment?.extension, "facility")}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mt: 2, mb: 3 }}>
            <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 1 }}>
              Select date
            </Typography>
            <DatePicker selectedDate={selectedDate} onDateChange={setSelectedDate} />
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 1 }}>
              Select time
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab label="Morning" value="morning" />
                <Tab label="Afternoon" value="afternoon" />
                <Tab label="Evening" value="evening" />
              </Tabs>
            </Box>

            <Grid container spacing={1.5}>
              {timeSlots[timeOfDay].map((time) => (
                <Grid item xs={4} key={time}>
                  <Button
                    variant={selectedTime === time ? "contained" : "outlined"}
                    fullWidth
                    onClick={() => setSelectedTime(time)}
                    sx={{
                      textTransform: "none",
                      borderRadius: 1.5,
                      py: 0.75,
                    }}
                  >
                    {time}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box>
            <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 1 }}>
              Visit type
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant={selectedVisitType === "Virtual Visit" ? "contained" : "outlined"}
                  fullWidth
                  startIcon={<Video size={18} />}
                  onClick={() => setSelectedVisitType("Virtual Visit")}
                  sx={{ textTransform: "none", justifyContent: "flex-start" }}
                >
                  Virtual visit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant={selectedVisitType === "In-Person" ? "contained" : "outlined"}
                  fullWidth
                  startIcon={<MapPin size={18} />}
                  onClick={() => setSelectedVisitType("In-Person")}
                  sx={{ textTransform: "none", justifyContent: "flex-start" }}
                >
                  In-person
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 1 }}>
          <Button onClick={() => setScheduleDialogOpen(false)} sx={{ textTransform: "none" }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={confirmSchedule}
            disabled={!selectedDate || !selectedTime}
            sx={{ textTransform: "none", fontWeight: 600 }}
          >
            Schedule appointment
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

